import React from 'react';

import Spacer from '@interness/web-core/src/components/structure/Spacer/Spacer';

import RolexModule   from '../../../components/RolexModule';
import RolexLayout   from '../../../components/Layout';
import KeepExploring from '../../../components/KeepExploring';


const RolexKollektionPage = () => {
  return (
    <RolexLayout>
      <RolexModule modulePage="watches"/>
      <Spacer/>
      <KeepExploring/>
      <Spacer/>
    </RolexLayout>
  )
};

export default RolexKollektionPage;